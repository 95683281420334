@import "../bin/variables";

.anketa__img {
  max-width: 100%;
  height: auto;
  display: block;
}

.anketa_content {
  padding: 45px 20px 10px 20px;
}

.anketa__title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: $color-dark-blue;
  margin-bottom: 30px;
}

.anketa__description {
  font-size: 13px;
  line-height: 18px;
  margin: 10px auto 30px auto;
}

.anketa__description-list-title {
  margin-bottom: 10px;
}

.anketa__description-list {
  list-style: decimal inside;
}

.anketa__description-list li {
  margin-top: 10px;
}

.anketa__button-wrapper {
  text-align: center;
}

.anketa__button {
  width: 100%;
  max-width: 320px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  background-color: $color-orange;
  color: $color-white;
  border: solid 2px $color-orange;
  padding: 15px 0 10px 0;
  margin: auto auto 30px auto;
  display: block;
  text-decoration: none;

  &:focus {
    background-color: $color-white;
    color: $color-orange;
  }
}

.anketa__button-download {
  margin-right: 5px;
}

@media #{$tablet-width} {
  .anketa__content-wrapper {
    max-width: 985px;
    margin: 0 auto;
  }

  .anketa__button {
    display: inline-block;
    margin: 20px 20px 40px 20px;
  }
}