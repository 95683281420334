// Colors:
$color-dark-blue: #27364d;
$color-white: #fff;
$color-light-grey: #f9f9f9;
$color-grey: #dedede;
$color-dark-grey: #b7b7b7;
$color-super-dark-grey: #636363;
$color-orange: #fe7f2d;
$color-black: #000;

//Sizes
$font-size-common-mobile: 12px;
$font-size-large-mobile: 14px;
$font-size-extra-large-mobile: 20px;

$font-size-common: 14px;
$font-size-large: 20px;
$font-size-extra-large: 24px;

// Fonts:
$font-awesome-font-family: "Font Awesome 5 Free";

// Media
$tablet-width: "(min-width: 768px)";
$desktop-width: "(min-width: 1024px)";