@import "../../bin/variables";

.header__header-wrapper {
  background: url("/img/main/about/main_back_768x765.jpg") no-repeat;
  background-size: cover;
  padding-top: 35px;
  padding-bottom: 40px;
}

.header__title-wrapper-container {
  padding-left: 30px;
}

.header__title {
  font-size: $font-size-extra-large-mobile;
  line-height: 24px;
  font-weight: bold;
  color: $color-dark-blue;
}

.header__description {
  margin-top: 12px;
  font-size: $font-size-large-mobile;
  color: $color-dark-blue;
}

.header__schema {
  display: block;
  margin: 40px auto 10px;
}

@media #{$tablet-width} {
  .header__header-wrapper {
    background: url("/img/main/about/main_back_1024x450.jpg") no-repeat center center;
    padding-top: 40px;
  }

  .header__title-wrapper-container {
    padding-top: 20px;
  }

  .header__title-wrapper {
    max-width: 970px;
    margin: 0 auto;
  }

  .header__title {
    font-size: $font-size-extra-large;
  }

  .header__schema {
    margin-top: 80px;
  }
}

@media #{$desktop-width} {
  .header__header-wrapper {
    background: url("/img/main/about/main_back_1920x450.jpg") no-repeat center center;
  }
}