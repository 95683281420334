@import "../bin/variables";

.footer__content {
  background-color: $color-dark-blue;
  padding: 20px 0;
  text-align: center;
}

.footer__content-text {
  font-size: 12px;
  line-height: 18px;
  color: $color-white;
}