@import "../../bin/variables";

.products {
  background: url("/img/main/products/products_back_768x500.jpg") no-repeat;
  background-size: cover;
  padding: 50px 35px 50px;
}

.products__carousel {
  position: relative;
  max-height: 380px;
}

.products__title {
  font-weight: bold;
  font-size: 20px;
  color: $color-white;
  text-align: center;
  margin-bottom: 40px;
}

.products__owl-carousel-mobile {
  max-width: 280px;
  margin: 0 auto;

  .products__item-wrapper {
    position: relative;
    margin: 0 auto 10px;
  }

  .products__item-wrapper img {
    display: block;
    border: 5px solid $color-white;
  }

  .products__item-body {
    text-align: center;
    line-height: 20px;
    font-size: 16px;
    color: $color-white;
    font-weight: bold;
    margin-top: 10px;
  }

  .products__carousel .owl-dots {
    margin-top: 20px !important;
  }
}

.products__carousel-next,
.products__carousel-prev {
  cursor: pointer;
  color: $color-white;
  font-size: 40px;
  text-align: center;
  line-height: 60px;
  position: absolute;
  top: calc(50% - 55px);
  z-index: 1;
}

.products__carousel-next {
  right: calc(50% - 160px);
}

.products__carousel-prev {
  left: calc(50% - 160px);
}

.products__button {
  .button {
    margin: 25px auto 20px;
  }
}

@media (min-width: 340px) {
  .products__carousel-next {
    right: calc(50% - 170px);
  }

  .products__carousel-prev {
    left: calc(50% - 170px);
  }
}

@media (min-width: 360px) {
  .products__carousel-next {
    right: calc(50% - 180px);
  }

  .products__carousel-prev {
    left: calc(50% - 180px);
  }
}

@media #{$tablet-width} {
  .products {
    background: url("/img/main/products/products_back_1024x500.jpg") no-repeat;
  }
}

@media #{$desktop-width} {
  .products {
    background: url("/img/main/products/products_back_1920x500.jpg") no-repeat;
    background-position-x: center;
    padding: 60px 35px 30px;
  }

  .products__title {
    margin-bottom: 35px;
  }

  .products__owl-carousel-desktop {
    display: block !important;
    max-width: 810px;
    margin: 0 auto;
    position: relative;

    .products__item-wrapper {
      display: flex;
      width: 810px;
      padding: 25px 60px 50px;

      img {
        border: 3px solid $color-white;
        margin-right: 60px;
        max-height: 270px;
      }
    }

    .products__item-body-title {
      color: $color-white;
      font-size: 16px;
      margin-bottom: 25px;
    }

    .products__item-body-desc {
      color: $color-white;
      font-size: 12px;
      line-height: 18px;
      height: 150px;
    }

    .owl-dots {
      position: absolute;
      bottom: 0;
      right: calc(50% - 15px);
    }
  }

  .products__link-wrapper {
    margin-top: 30px;
  }

  .products__carousel-next,
  .products__carousel-prev {
    background-color: transparent;
  }

  .products__carousel-next {
    right: calc(50% - 405px);
  }

  .products__carousel-prev {
    left: calc(50% - 405px);
  }
}