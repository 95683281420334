@import "../../bin/variables";

.contacts {
  padding: 45px 20px 25px;
}

.contacts__title {
  color: $color-dark-blue;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.contacts__wrapper {
  max-width: 740px;
}

.contacts__data-wrapper {
  margin-left: 10px;
}

.contacts__name {
  margin-bottom: 30px;
}

.contacts__map {
  margin: 40px auto 35px;
  width: 100%;
  height: 200px;
}

.contacts__icon-map {
  margin-right: 20px;
}

.contacts__icon-fax {
  margin-right: 14px;
}

.contacts__icon-mob {
  margin-right: 21px;
}

.contacts__icon-mail {
  margin-right: 15px;
}

.contacts__data li {
  margin-bottom: 15px;
  font-size: 14px;
  width: 250px;
}

@media #{$tablet-width} {
  .contacts__wrapper {
    display: flex;
    flex-direction: row-reverse;
    margin: auto;
  }

  .contacts__data-wrapper {
    margin-top: 90px;
  }

  .contacts__map {
    margin-top: 40px;
    height: 300px;
  }
}

@media #{$desktop-width} {
  .contacts{
    padding: 60px 20px 20px;
  }

  .contacts__data-wrapper {
    margin-top: 100px;
  }

  .contacts__map {
    margin-top: 50px;
  }
}