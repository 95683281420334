/**
 * 1. Change the default font family in all browsers (opinionated).
 * 2. Correct the line height in all browsers.
 * 3. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */
html {
  font-family: sans-serif;
  /* 1 */
  line-height: 1.15;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 3 */
  -webkit-text-size-adjust: 100%;
  /* 3 */
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit; }

/* Sections
   ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Add the correct display in IE 9-.
 */
article, aside, footer, header, nav, section, figcaption, figure, main {
  display: block; }

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/* Text-level semantics
   ========================================================================== */
/**
 * 1. Remove the gray background on active links in IE 10.
 * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
 */
a {
  background-color: transparent;
  /* 1 */
  -webkit-text-decoration-skip: objects;
  /* 2 */ }

/**
 * Remove the outline on focused links when they are also active or hovered
 * in all browsers (opinionated).
 */
a:active,
a:hover {
  outline-width: 0; }

/**
 * 1. Remove the bottom border in Firefox 39-.
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */ }

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/**
 * 1. Change the font styles in all browsers (opinionated).
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: sans-serif;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**Enable panning and pinch zoom gestures, but disable additional non-standard gestures such as double-tap to zoom. Disabling double-tap to zoom removes the need for browsers to delay the generation of click events when the user taps the screen. */
a, area, button, input, label, select, textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation; }

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible; }

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none; }

/**
 * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
 *    controls in Android 4.
 * 2. Correct the inability to style clickable types in iOS and Safari.
 */
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
  /* 2 */ }

/**
 * Remove the inner border and padding in Firefox.
 */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in IE 9-.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Remove the default vertical scrollbar in IE.
 */
textarea {
  overflow: auto; }

/**
 * 1. Add the correct box sizing in IE 10-.
 * 2. Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/* Scripting
   ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/* Hidden
   ========================================================================== */
/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/* Reset
   ========================================================================== */
h1, h2, h3, h4, h5, h6, p, blockquote, em, img, i, dl, dt, ol, ul, li, video, canvas {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span {
  outline: none; }

ul, ol {
  list-style-type: none; }

input[type="submit"],
button {
  cursor: pointer; }
  input[type="submit"]::-moz-focus-inner,
  button::-moz-focus-inner {
    padding: 0;
    border: 0; }

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%; }

/** to remove the rounded in IOS*/
textarea,
input[type="text"],
input[type="button"],
input[type="submit"] {
  -webkit-appearance: none;
  border-radius: 0; }

.header {
  flex: 0 0 50px; }

.footer {
  flex: 0 0 50px; }

.main {
  flex: 1 0 auto; }

body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
  position: relative;
  font-family: 'Open Sans', sans-serif; }

.hidden-on-mobile {
  display: none !important; }

.visible-on-mobile {
  display: inline; }

.button {
  width: 200px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;
  background-color: #fe7f2d;
  color: #fff;
  border: solid 2px #fe7f2d;
  padding: 14px 0 11px 0;
  display: block;
  text-align: center;
  text-decoration: none; }
  .button:focus {
    background-color: #fff;
    color: #fe7f2d; }

.text-indent {
  text-indent: 35px; }

@media (min-width: 768px) {
  .visible-on-mobile {
    display: none !important; }
  .visible-on-tablet {
    display: block !important; } }

@media (min-width: 1024px) {
  .hidden-on-desktop {
    display: none !important; }
  .visible-on-desktop {
    display: block !important; } }

.main-nav {
  background-color: #27364d;
  padding: 11px 30px 11px;
  position: relative;
  z-index: 1; }

.main-nav__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.logo {
  font-weight: bold;
  color: #fff;
  font-size: 18px;
  text-decoration: none; }
  .logo a:hover, .logo a:focus, .logo a:active {
    background: none;
    outline: none; }
  .logo a {
    font-weight: bold;
    color: #fff;
    font-size: 18px;
    text-decoration: none; }

.main-nav__toggle-menu {
  border: 0;
  background-color: transparent;
  color: #fff;
  font-size: 25px;
  padding: 0; }

.main-nav__items {
  display: none;
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  padding-bottom: 30px;
  background-color: #f9f9f9; }

.main-nav__item:first-child a {
  padding-top: 20px; }

.main-nav__item a {
  display: block;
  padding: 15px 0;
  max-width: 200px;
  margin: auto;
  color: #636363;
  font-weight: 600;
  font-size: 14px;
  text-decoration: none;
  text-align: center;
  border-bottom: 1px solid #636363; }
  .main-nav__item a:hover, .main-nav__item a:focus, .main-nav__item a:active {
    outline: none;
    color: #27364d; }

@media (min-width: 768px) {
  .main-nav {
    padding: 17px 30px 15px; }
  .main-nav__wrapper {
    max-width: 945px;
    margin: 0 auto; }
  .main-nav__toggle-menu {
    display: none; }
  .main-nav__items {
    position: static;
    padding: 0;
    display: block !important;
    background-color: transparent;
    width: auto; }
  .main-nav__item {
    display: inline-block;
    margin-left: 40px; }
    .main-nav__item a {
      border: 0;
      padding: 0 !important;
      color: #dedede; }
      .main-nav__item a:focus, .main-nav__item a:hover, .main-nav__item a:active {
        outline: none;
        color: #fff; } }

@media (min-width: 1024px) {
  .main-nav__item {
    margin-left: 95px; } }

.footer__content {
  background-color: #27364d;
  padding: 20px 0;
  text-align: center; }

.footer__content-text {
  font-size: 12px;
  line-height: 18px;
  color: #fff; }

.header__header-wrapper {
  background: url("/img/main/about/main_back_768x765.jpg") no-repeat;
  background-size: cover;
  padding-top: 35px;
  padding-bottom: 40px; }

.header__title-wrapper-container {
  padding-left: 30px; }

.header__title {
  font-size: 20px;
  line-height: 24px;
  font-weight: bold;
  color: #27364d; }

.header__description {
  margin-top: 12px;
  font-size: 14px;
  color: #27364d; }

.header__schema {
  display: block;
  margin: 40px auto 10px; }

@media (min-width: 768px) {
  .header__header-wrapper {
    background: url("/img/main/about/main_back_1024x450.jpg") no-repeat center center;
    padding-top: 40px; }
  .header__title-wrapper-container {
    padding-top: 20px; }
  .header__title-wrapper {
    max-width: 970px;
    margin: 0 auto; }
  .header__title {
    font-size: 24px; }
  .header__schema {
    margin-top: 80px; } }

@media (min-width: 1024px) {
  .header__header-wrapper {
    background: url("/img/main/about/main_back_1920x450.jpg") no-repeat center center; } }

.about {
  padding: 60px 30px 40px 30px; }

.about__title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px; }

.about__description-wrapper {
  margin-bottom: 45px; }

.about__description {
  font-size: 14px;
  line-height: 18px; }

.about__items {
  display: flex;
  flex-wrap: wrap; }

.about__item {
  width: 50%;
  text-align: center;
  margin-bottom: 30px; }

.about__item-img {
  margin-bottom: 10px; }

.about__item-description {
  font-size: 13px;
  line-height: 16px;
  color: #fe7f2d;
  max-width: 135px;
  margin: 0 auto; }

.about__description-wrapper ul {
  list-style-type: circle;
  font-size: 14px;
  line-height: 17px;
  margin: 10px 0 15px 80px; }
  .about__description-wrapper ul li {
    margin-bottom: 5px; }

@media (min-width: 768px) {
  .about {
    max-width: 940px;
    margin: 0 auto; }
  .about__title {
    margin-bottom: 40px; }
  .about__description-wrapper {
    margin-bottom: 55px; }
  .about__description-wrapper ul {
    font-size: 13px; }
  .about__description {
    font-size: 13px;
    line-height: 18px; }
  .about__items {
    max-width: 645px;
    margin: 0 auto 20px; }
  .about__item {
    width: auto;
    margin-right: 35px;
    margin-bottom: 0; }
    .about__item:last-child {
      margin-right: 0; }
  .about__item-description {
    font-size: 12px; } }

.products {
  background: url("/img/main/products/products_back_768x500.jpg") no-repeat;
  background-size: cover;
  padding: 50px 35px 50px; }

.products__carousel {
  position: relative;
  max-height: 380px; }

.products__title {
  font-weight: bold;
  font-size: 20px;
  color: #fff;
  text-align: center;
  margin-bottom: 40px; }

.products__owl-carousel-mobile {
  max-width: 280px;
  margin: 0 auto; }
  .products__owl-carousel-mobile .products__item-wrapper {
    position: relative;
    margin: 0 auto 10px; }
  .products__owl-carousel-mobile .products__item-wrapper img {
    display: block;
    border: 5px solid #fff; }
  .products__owl-carousel-mobile .products__item-body {
    text-align: center;
    line-height: 20px;
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    margin-top: 10px; }
  .products__owl-carousel-mobile .products__carousel .owl-dots {
    margin-top: 20px !important; }

.products__carousel-next,
.products__carousel-prev {
  cursor: pointer;
  color: #fff;
  font-size: 40px;
  text-align: center;
  line-height: 60px;
  position: absolute;
  top: calc(50% - 55px);
  z-index: 1; }

.products__carousel-next {
  right: calc(50% - 160px); }

.products__carousel-prev {
  left: calc(50% - 160px); }

.products__button .button {
  margin: 25px auto 20px; }

@media (min-width: 340px) {
  .products__carousel-next {
    right: calc(50% - 170px); }
  .products__carousel-prev {
    left: calc(50% - 170px); } }

@media (min-width: 360px) {
  .products__carousel-next {
    right: calc(50% - 180px); }
  .products__carousel-prev {
    left: calc(50% - 180px); } }

@media (min-width: 768px) {
  .products {
    background: url("/img/main/products/products_back_1024x500.jpg") no-repeat; } }

@media (min-width: 1024px) {
  .products {
    background: url("/img/main/products/products_back_1920x500.jpg") no-repeat;
    background-position-x: center;
    padding: 60px 35px 30px; }
  .products__title {
    margin-bottom: 35px; }
  .products__owl-carousel-desktop {
    display: block !important;
    max-width: 810px;
    margin: 0 auto;
    position: relative; }
    .products__owl-carousel-desktop .products__item-wrapper {
      display: flex;
      width: 810px;
      padding: 25px 60px 50px; }
      .products__owl-carousel-desktop .products__item-wrapper img {
        border: 3px solid #fff;
        margin-right: 60px;
        max-height: 270px; }
    .products__owl-carousel-desktop .products__item-body-title {
      color: #fff;
      font-size: 16px;
      margin-bottom: 25px; }
    .products__owl-carousel-desktop .products__item-body-desc {
      color: #fff;
      font-size: 12px;
      line-height: 18px;
      height: 150px; }
    .products__owl-carousel-desktop .owl-dots {
      position: absolute;
      bottom: 0;
      right: calc(50% - 15px); }
  .products__link-wrapper {
    margin-top: 30px; }
  .products__carousel-next,
  .products__carousel-prev {
    background-color: transparent; }
  .products__carousel-next {
    right: calc(50% - 405px); }
  .products__carousel-prev {
    left: calc(50% - 405px); } }

.sectors {
  padding-top: 60px;
  text-align: center; }

.sectors__title {
  font-size: 20px;
  margin-bottom: 50px;
  color: #27364d; }

.sectors__sector-item {
  position: relative;
  padding: 0 20px;
  text-align: left;
  border-bottom: 1px #dedede solid; }
  .sectors__sector-item:first-child {
    border-top: 1px #dedede solid; }
  .sectors__sector-item:after {
    content: "\f146";
    display: block;
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-size: 20px;
    top: 17px;
    right: 20px;
    color: #27364d; }

.sectors__sector-item--closed {
  padding-left: 0;
  padding-right: 0;
  background-color: #f9f9f9; }
  .sectors__sector-item--closed .sectors__sector-title {
    padding-left: 20px;
    padding-right: 45px;
    font-weight: normal; }
  .sectors__sector-item--closed .sectors__sector-body {
    display: none; }
  .sectors__sector-item--closed:after {
    content: "\f0fe";
    display: block;
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-size: 20px;
    top: 17px;
    right: 20px;
    color: #27364d; }

.sectors__sector-body {
  font-size: 14px; }

.sectors__sector-title {
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0; }

.sectors__sector-image {
  display: block;
  margin: 20px auto;
  max-height: 200px;
  max-width: 100%; }

@media (min-width: 768px) {
  .sectors__sector-item--closed .sectors__sector-title {
    padding-left: 0; }
  .sectors__sector-item {
    padding-left: 50px;
    padding-right: 50px; }
  .sectors__sector-image {
    margin: 30px auto;
    height: 200px; } }

@media (min-width: 1024px) {
  .sectors {
    max-width: 945px;
    margin: 0 auto; }
  .sectors__sector-list {
    position: relative;
    min-height: 305px; }
  .sectors__sector-item {
    padding: 0;
    background-color: #f9f9f9;
    position: static;
    border: 0; }
    .sectors__sector-item:first-child {
      border-top: 1px #dedede solid; }
    .sectors__sector-item:after {
      display: none; }
  .sectors__sector-title {
    padding: 15px 20px;
    font-size: 14px;
    max-width: 350px;
    background-color: #f9f9f9;
    border-bottom: 1px #dedede solid;
    border-left: 1px #dedede solid; }
  .sectors__sector-item--closed {
    background-color: transparent; }
    .sectors__sector-item--closed .sectors__sector-title {
      background-color: transparent;
      padding-left: 20px;
      border-right: 1px #dedede solid; }
  .sectors__sector-body {
    height: 440px;
    background-color: #f9f9f9;
    border-top: 1px #dedede solid;
    border-bottom: 1px #dedede solid;
    border-right: 1px #dedede solid;
    padding: 30px;
    position: absolute;
    left: 350px;
    top: 0; }
  .sectors__sector-text {
    font-size: 12px; }
  .sectors__sector-image {
    border: 3px solid #fff; } }

.cert {
  padding: 55px 40px 35px;
  background: url("/img/main/cert/cert_back_768x1400.jpg") no-repeat;
  background-size: cover;
  position: relative; }

.cert__title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #fff; }

.cert__carousel-next,
.cert__carousel-prev {
  cursor: pointer;
  width: 60px;
  height: 60px;
  color: #fff;
  font-size: 40px;
  text-align: center;
  line-height: 60px;
  position: absolute;
  top: calc(50% - 5px);
  z-index: 1; }

.cert__carousel-next {
  right: calc(50% - 170px); }

.cert__carousel-prev {
  left: calc(50% - 170px); }

.cert__item-wrapper {
  border: 5px solid #fff;
  position: relative;
  margin: 0 auto 25px; }
  .cert__item-wrapper img {
    display: block; }

.cert__owl-carousel {
  max-width: 280px;
  margin: 40px auto 0; }

@media (min-width: 330px) {
  .cert__carousel-next {
    right: calc(50% - 180px); }
  .cert__carousel-prev {
    left: calc(50% - 180px); } }

@media (min-width: 350px) {
  .cert__carousel-next {
    right: calc(50% - 190px); }
  .cert__carousel-prev {
    left: calc(50% - 190px); } }

@media (min-width: 370px) {
  .cert__carousel-next {
    right: calc(50% - 200px); }
  .cert__carousel-prev {
    left: calc(50% - 200px); } }

@media (min-width: 768px) {
  .cert {
    background: url("/img/main/cert/cert_back_1024x605.jpg") no-repeat; }
  .cert__carousel-next {
    right: calc(50% - 220px); }
  .cert__carousel-prev {
    left: calc(50% - 220px); } }

@media (min-width: 1024px) {
  .cert {
    background: url("/img/main/cert/cert_back_1920x484.jpg") no-repeat;
    padding-top: 65px; }
  .cert__title {
    margin-bottom: 10px; }
  .cert__owl-carousel {
    display: block !important;
    max-width: 650px;
    margin: 50px auto 0;
    position: relative; }
    .cert__owl-carousel .cert__item-wrapper {
      max-width: 170px; }
  .cert__carousel-next,
  .cert__carousel-prev {
    background-color: transparent;
    color: #fff; }
  .cert__carousel-next {
    right: calc(50% - 380px); }
  .cert__carousel-prev {
    left: calc(50% - 380px); } }

.contacts {
  padding: 45px 20px 25px; }

.contacts__title {
  color: #27364d;
  text-align: center;
  font-size: 20px;
  font-weight: bold; }

.contacts__wrapper {
  max-width: 740px; }

.contacts__data-wrapper {
  margin-left: 10px; }

.contacts__name {
  margin-bottom: 30px; }

.contacts__map {
  margin: 40px auto 35px;
  width: 100%;
  height: 200px; }

.contacts__icon-map {
  margin-right: 20px; }

.contacts__icon-fax {
  margin-right: 14px; }

.contacts__icon-mob {
  margin-right: 21px; }

.contacts__icon-mail {
  margin-right: 15px; }

.contacts__data li {
  margin-bottom: 15px;
  font-size: 14px;
  width: 250px; }

@media (min-width: 768px) {
  .contacts__wrapper {
    display: flex;
    flex-direction: row-reverse;
    margin: auto; }
  .contacts__data-wrapper {
    margin-top: 90px; }
  .contacts__map {
    margin-top: 40px;
    height: 300px; } }

@media (min-width: 1024px) {
  .contacts {
    padding: 60px 20px 20px; }
  .contacts__data-wrapper {
    margin-top: 100px; }
  .contacts__map {
    margin-top: 50px; } }

.anketa__img {
  max-width: 100%;
  height: auto;
  display: block; }

.anketa_content {
  padding: 45px 20px 10px 20px; }

.anketa__title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: #27364d;
  margin-bottom: 30px; }

.anketa__description {
  font-size: 13px;
  line-height: 18px;
  margin: 10px auto 30px auto; }

.anketa__description-list-title {
  margin-bottom: 10px; }

.anketa__description-list {
  list-style: decimal inside; }

.anketa__description-list li {
  margin-top: 10px; }

.anketa__button-wrapper {
  text-align: center; }

.anketa__button {
  width: 100%;
  max-width: 320px;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 18px;
  font-weight: bold;
  background-color: #fe7f2d;
  color: #fff;
  border: solid 2px #fe7f2d;
  padding: 15px 0 10px 0;
  margin: auto auto 30px auto;
  display: block;
  text-decoration: none; }
  .anketa__button:focus {
    background-color: #fff;
    color: #fe7f2d; }

.anketa__button-download {
  margin-right: 5px; }

@media (min-width: 768px) {
  .anketa__content-wrapper {
    max-width: 985px;
    margin: 0 auto; }
  .anketa__button {
    display: inline-block;
    margin: 20px 20px 40px 20px; } }

.prod {
  padding: 40px 0 60px; }

.prod__title {
  font-size: 20px;
  color: #27364d;
  font-weight: bold;
  margin-bottom: 40px;
  text-align: center; }

.prod__category-item-title, .prod__item-title, .prod__category-back {
  border-bottom: 1px #dedede solid;
  cursor: pointer; }

.prod__category-item-title, .prod__category-back {
  padding: 20px 55px 20px 20px; }

.prod__item-title {
  padding: 20px; }

.prod__category-back {
  position: relative; }
  .prod__category-back p {
    margin-left: 30px; }
  .prod__category-back:before {
    content: "\f104";
    display: block;
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 20px;
    top: 17px;
    left: 20px;
    color: #27364d; }

.prod__category-item--closed .prod__item-title, .prod__category-item--closed .prod__item-body {
  display: none; }

.prod__category-item--closed .prod__category-item-title {
  position: relative; }

.prod__category-item--closed .prod__category-item-title:after {
  content: "\f105";
  display: block;
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 20px;
  top: 17px;
  right: 30px;
  color: #27364d; }

.prod__category-item--closed:first-child .prod__category-item-title {
  border-top: 1px #dedede solid; }

.prod__category-item--opened .prod__category-item-title {
  display: none; }

.prod__category-item--opened .prod__item:first-child .prod__item-title {
  border-top: 1px #dedede solid; }

.prod__category-item--opened .prod__item-body {
  display: none; }

.prod__category-item--inside .prod__category-item-title {
  position: relative;
  border-top: 1px #dedede solid; }
  .prod__category-item--inside .prod__category-item-title p {
    margin-left: 30px; }
  .prod__category-item--inside .prod__category-item-title:before {
    content: "\f104";
    display: block;
    position: absolute;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 20px;
    top: 17px;
    left: 20px;
    color: #27364d; }

.prod__category-item--inside .prod__item-title {
  border: none;
  padding: 40px 20px;
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  color: #27364d; }

.prod__category-item--inside .prod__item-body {
  padding: 0 20px;
  font-size: 14px; }

.prod__category-item--inside .prod__item-image {
  margin: 30px auto;
  text-align: center;
  display: table;
  table-layout: fixed; }
  .prod__category-item--inside .prod__item-image img {
    max-width: 100%;
    height: auto; }

.prod__category-item--hide, .prod__item--hide, .prod__category-back--hide {
  display: none; }

h4 {
  font-size: 14px;
  margin: 0 auto 15px;
  text-align: center;
  color: #27364d; }

.prod__item-figcaption {
  font-size: 12px;
  margin-top: 5px;
  display: table-caption;
  caption-side: bottom; }

.prod__item ul {
  list-style-type: circle;
  font-size: 14px;
  line-height: 17px;
  margin: 10px 0 15px 40px; }
  .prod__item ul li {
    margin-bottom: 5px; }

@media (min-width: 768px) {
  .prod {
    max-width: 500px;
    margin: auto; }
  .prod__category-item-title, .prod__item-title, .prod__category-back {
    border-left: 1px #dedede solid;
    border-right: 1px #dedede solid; }
  .prod__item-two-imgs-block {
    display: flex; } }

@media (min-width: 1024px) {
  .prod {
    max-width: 945px; }
  .prod__category-item-title, .prod__item-title {
    font-size: 14px;
    width: 330px; }
  .prod__category-item-title {
    font-weight: bold;
    color: #27364d;
    padding: 15px 50px 15px 15px; }
  .prod__item-title {
    padding: 15px 15px 15px 30px; }
  .prod__item-body p {
    font-size: 13px;
    line-height: 17px; }
  .prod__category-back {
    display: none; }
  .prod__category-list {
    position: relative; }
  .prod__category-item--opened .prod__category-item-title {
    display: block;
    border-top: none;
    position: relative; }
    .prod__category-item--opened .prod__category-item-title:after {
      content: "\f068";
      display: block;
      position: absolute;
      font-family: "Font Awesome 5 Free";
      font-size: 14px;
      font-weight: 900;
      top: 17px;
      left: 290px;
      color: #27364d; }
  .prod__category-item--opened .prod__item:first-child .prod__item-title {
    border-top: none; }
  .prod__category-item--opened:first-child .prod__category-item-title {
    border-top: 1px #dedede solid; }
  .prod__category-item--closed .prod__item-title {
    display: none; }
  .prod__category-item--closed .prod__category-item-title:after {
    content: "\f067";
    font-size: 14px;
    left: 290px; }
  .prod__category-item--default .prod__item {
    display: block; }
    .prod__category-item--default .prod__item .prod__item-title {
      display: block; }
  .prod__category-item--default .prod__category-item-title {
    display: block;
    position: relative; }
    .prod__category-item--default .prod__category-item-title:after {
      content: "\f068";
      display: block;
      position: absolute;
      font-family: "Font Awesome 5 Free";
      font-size: 14px;
      font-weight: 900;
      top: 16px;
      left: 290px;
      color: #27364d; }
  .prod__item--opened .prod__item-title {
    color: #fe7f2d; }
  .prod__item--opened .prod__item-body {
    display: block;
    position: absolute;
    left: 380px;
    top: 65px;
    padding: 0 20px;
    width: 565px; }
  .prod__item-image {
    margin: 20px auto;
    text-align: center;
    display: table;
    table-layout: fixed; }
  .prod__item-title.prod__item-title--cloned {
    display: block;
    position: absolute;
    left: 380px;
    top: 15px;
    padding: 0 20px;
    border: none;
    width: 565px;
    text-align: center;
    font-size: 16px;
    color: #27364d;
    font-weight: bold; }
  .prod__item ul {
    font-size: 13px;
    margin: 10px 0 15px 80px; } }

.clients {
  padding: 60px 15px 30px;
  margin: 0 auto;
  width: 320px; }

.clients__title {
  font-size: 20px;
  color: #27364d;
  text-align: center;
  margin-bottom: 50px; }

.clients__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }

.clients__item-wrapper {
  width: 295px;
  margin-bottom: 40px; }

.clients__item-img {
  width: 100%;
  margin-bottom: 5px; }

.clients__item-title {
  font-size: 14px;
  line-height: 18px;
  margin: 5px 0;
  color: #27364d; }
  .clients__item-title a {
    text-decoration: none;
    color: #27364d; }
    .clients__item-title a:visited {
      color: #27364d; }

.clients__item-details {
  font-size: 12px;
  color: #636363; }

.clients__item-desc {
  font-size: 13px;
  margin: 5px 0; }

.clients__button .button {
  margin: 0 auto 20px; }

@media (min-width: 768px) {
  .clients {
    width: 680px; }
  .clients__list {
    justify-content: space-between; } }

@media (min-width: 1024px) {
  .clients {
    width: 1005px; }
  .clients__list {
    justify-content: start; }
  .clients__item-wrapper {
    margin: 0 15px 40px; } }
