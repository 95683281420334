@import "../../bin/variables";

.cert {
  padding: 55px 40px 35px;
  background: url("/img/main/cert/cert_back_768x1400.jpg") no-repeat;
  background-size: cover;
  position: relative;
}

.cert__title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  color: $color-white;
}

.cert__carousel-next,
.cert__carousel-prev {
  cursor: pointer;
  width: 60px;
  height: 60px;
  color: $color-white;
  font-size: 40px;
  text-align: center;
  line-height: 60px;
  position: absolute;
  top: calc(50% - 5px);
  z-index: 1;
}

.cert__carousel-next {
  right: calc(50% - 170px);
}

.cert__carousel-prev {
  left: calc(50% - 170px);
}

.cert__item-wrapper {
  border: 5px solid $color-white;
  position: relative;
  margin: 0 auto 25px;

  img {
    display: block;
  }
}

.cert__owl-carousel {
  max-width: 280px;
  margin: 40px auto 0;
}

@media (min-width: 330px) {
  .cert__carousel-next {
    right: calc(50% - 180px);
  }

  .cert__carousel-prev {
    left: calc(50% - 180px);
  }
}

@media (min-width: 350px) {
  .cert__carousel-next {
    right: calc(50% - 190px);
  }

  .cert__carousel-prev {
    left: calc(50% - 190px);
  }
}

@media (min-width: 370px) {
  .cert__carousel-next {
    right: calc(50% - 200px);
  }

  .cert__carousel-prev {
    left: calc(50% - 200px);
  }
}

@media #{$tablet-width}{
  .cert {
    background: url("/img/main/cert/cert_back_1024x605.jpg") no-repeat;
  }

  .cert__carousel-next {
    right: calc(50% - 220px);
  }

  .cert__carousel-prev {
    left: calc(50% - 220px);
  }
}

@media #{$desktop-width} {
  .cert {
    background: url("/img/main/cert/cert_back_1920x484.jpg") no-repeat;
    padding-top: 65px;
  }

  .cert__title {
    margin-bottom: 10px;
  }

  .cert__owl-carousel {
    display: block !important;
    max-width: 650px;
    margin: 50px auto 0;
    position: relative;

    .cert__item-wrapper {
      max-width: 170px;
    }
  }

  .cert__carousel-next,
  .cert__carousel-prev {
    background-color: transparent;
    color: $color-white;
  }

  .cert__carousel-next {
    right: calc(50% - 380px);
  }

  .cert__carousel-prev {
    left: calc(50% - 380px);
  }

}