@import "../../bin/variables";

.about {
  padding: 60px 30px 40px 30px;
}

.about__title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 40px;
}

.about__description-wrapper {
  margin-bottom: 45px;
}

.about__description {
  font-size: 14px;
  line-height: 18px;
}

.about__items {
  display: flex;
  flex-wrap: wrap;
}

.about__item {
  width: 50%;
  text-align: center;
  margin-bottom: 30px;
}

.about__item-img {
  margin-bottom: 10px;
}

.about__item-description {
  font-size: 13px;
  line-height: 16px;
  color: $color-orange;
  max-width: 135px;
  margin: 0 auto;
}

.about__description-wrapper ul {
  list-style-type: circle;
  font-size: 14px;
  line-height: 17px;
  margin: 10px 0 15px 80px;

  li {
    margin-bottom: 5px;
  }
}

@media #{$tablet-width} {
  .about {
    max-width: 940px;
    margin: 0 auto;
  }

  .about__title {
    margin-bottom: 40px;
  }

  .about__description-wrapper {
    margin-bottom: 55px;
  }

  .about__description-wrapper ul {
    font-size: 13px;
  }

  .about__description {
    font-size: 13px;
    line-height: 18px;
  }

  .about__items {
    max-width: 645px;
    margin: 0 auto 20px;
  }

  .about__item {
    width: auto;
    margin-right: 35px;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .about__item-description {
    font-size: 12px;
  }
}