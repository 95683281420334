@import "../../bin/variables";

.sectors {
  padding-top: 60px;
  text-align: center;
}

.sectors__title {
  font-size: 20px;
  margin-bottom: 50px;
  color: $color-dark-blue;
}

.sectors__sector-item {
  position: relative;
  padding: 0 20px;
  text-align: left;
  border-bottom: 1px $color-grey solid;

  &:first-child {
    border-top: 1px $color-grey solid;
  }

  &:after {
    content: "\f146";
    display: block;
    position: absolute;
    font-family: $font-awesome-font-family;
    font-size: 20px;
    top: 17px;
    right: 20px;
    color: $color-dark-blue;
  }
}

.sectors__sector-item--closed {
  padding-left: 0;
  padding-right: 0;
  background-color: $color-light-grey;

  .sectors__sector-title {
    padding-left: 20px;
    padding-right: 45px;
    font-weight: normal;
  }

  .sectors__sector-body {
    display: none;
  }

  &:after {
    content: "\f0fe";
    display: block;
    position: absolute;
    font-family: $font-awesome-font-family;
    font-size: 20px;
    top: 17px;
    right: 20px;
    color: $color-dark-blue;
  }
}

.sectors__sector-body {
  font-size: 14px;
}

.sectors__sector-title {
  font-size: 16px;
  font-weight: bold;
  padding: 20px 0;
}

.sectors__sector-image {
  display: block;
  margin: 20px auto;
  max-height: 200px;
  max-width: 100%;
}

@media #{$tablet-width} {
  .sectors__sector-item--closed {
    .sectors__sector-title {
      padding-left: 0;
    }
  }

  .sectors__sector-item {
    padding-left: 50px;
    padding-right: 50px;
  }

  .sectors__sector-image {
    margin: 30px auto;
    height: 200px;
  }
}

@media #{$desktop-width} {
  .sectors {
    max-width: 945px;
    margin: 0 auto;
  }

  .sectors__sector-list {
    position: relative;
    min-height: 305px;
  }

  .sectors__sector-item {
    padding: 0;
    background-color: $color-light-grey;
    position: static;
    border: 0;

    &:first-child {
      border-top: 1px $color-grey solid;
    }

    &:after {
      display: none;
    }
  }

  .sectors__sector-title {
    padding: 15px 20px;
    font-size: 14px;
    max-width: 350px;
    background-color: $color-light-grey;
    border-bottom: 1px $color-grey solid;
    border-left: 1px $color-grey solid;
  }

  .sectors__sector-item--closed {
    background-color: transparent;
    .sectors__sector-title {
      background-color: transparent;
      padding-left: 20px;
      border-right: 1px $color-grey solid;
    }
  }

  .sectors__sector-body {
    height: 440px;
    background-color: $color-light-grey;
    border-top: 1px $color-grey solid;
    border-bottom: 1px $color-grey solid;
    border-right: 1px $color-grey solid;
    padding: 30px;
    position: absolute;
    left: 350px;
    top: 0;
  }

  .sectors__sector-text {
    font-size: 12px;
  }

  .sectors__sector-image {
    border: 3px solid $color-white;
  }
}