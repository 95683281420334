@import "variables";

body {

}
.header {
  flex: 0 0 50px;
}

.footer {
  flex: 0 0 50px;
}

.main {
  flex: 1 0 auto;
}

body {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100vh;
  position: relative;
  font-family: 'Open Sans', sans-serif;
}

.hidden-on-mobile {
  display: none !important;
}

.visible-on-mobile {
  display: inline;
}

.button {
  width: 200px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;;
  line-height: 18px;
  background-color: $color-orange;
  color: $color-white;
  border: solid 2px $color-orange;
  padding: 14px 0 11px 0;
  display: block;
  text-align: center;
  text-decoration: none;

  &:focus {
    background-color: $color-white;
    color: $color-orange;
  }
}

.text-indent {
  text-indent:  35px;
}

@media #{$tablet-width} {
  .visible-on-mobile {
    display: none !important;
  }

  .visible-on-tablet {
    display: block !important;
  }
}

@media #{$desktop-width} {
  .hidden-on-desktop {
    display: none !important;
  }

  .visible-on-desktop {
    display: block !important;
  }
}